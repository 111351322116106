<template lang="html">
    <div class="container-fluid p-5">
        <div class="row">
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">အသုံးပြုသူ</h5>
                        <p class="card-text">{{dashboard.pending}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">ဝန်ထမ်း</h5>
                        <p class="card-text">{{dashboard.approve}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">ဌာန</h5>
                        <p class="card-text">{{dashboard.shipping}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">သတင်း</h5>
                        <p class="card-text">{{dashboard.complete}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Enews </h5>
                        <p class="card-text">{{dashboard.sale}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">HOD</h5>
                        <p class="card-text">{{dashboard.transporter}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">ရာထူး</h5>
                        <p class="card-text">{{dashboard.distributor}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">ခွင့်ပြုချက်များ</h5>
                        <p class="card-text"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'dashboard',
        metaInfo: {
            title: "Home",
            titleTemplate: "%s ← SC Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                dashboard: [],
            }
        },
        computed: {
            ...mapState({})
        },
        methods: {
            ...mapActions({
                dashboardAction: 'dashboardAction',
            }),
            async dashboardDetail() {
                this.isLoading = true;
                await this.dashboardAction().then(res => {
                    if (res.status == "success") {
                        this.dashboard = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
        },
        async mounted() {
            this.isLoading = true
            this.dashboardDetail()
        }
    }
</script>


<style scoped lang="scss">
</style>